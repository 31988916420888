import { useGame, usePlayer } from "@empirica/core/player/classic/react";
import { Box, Button, Flex, Heading, Slider, Text, VisuallyHidden } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import "../node_modules/@empirica/core/dist/player-classic-react.css";
import { CompCarousel } from "./Carousel";
import { CompTable } from "./Table.jsx";
import { Profile } from "./Profile";
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import * as Tooltip from '@radix-ui/react-tooltip';



export function Pairwise({ useSlider }) {
  const player = usePlayer();
  const game = useGame();
  const playerScore = player.get("score");
  const pggConfiguration = game.get("pggConditions");
  const comparisonArray = game.get("comparisonArray");
  const config_A = pggConfiguration[comparisonArray[playerScore][0]];
  const config_B = pggConfiguration[comparisonArray[playerScore][1]];

  const [submitButtonsEnabled, setSubmitButtonsEnabled] = useState(true);
  const [selectionButtonsEnabled, setSelectionButtonsEnabled] = useState(true);


  function handleSubmit(selection) {
    // Disable the buttons first to prevent accidental spam
    setSelectionButtonsEnabled(false);

    // Record the comparison and update the player's score
    player.set("comparisons", [
      ...player.get("comparisons"),
      {
        config_A: comparisonArray[playerScore][0],
        config_B: comparisonArray[playerScore][1],
        selection: selection,
        timestamp: Date.now(),
      },
    ]);

    console.log("Player comparisons: ", player.get("comparisons"));

    if (useSlider) {
      player.round.set("guess", undefined);
    }

    // If all comparisons have been made, set the submit flag to true
    if (player.get("comparisons").length === comparisonArray.length) {
      console.log("All comparisons have been made!");
      player.stage.set("submit", true);
    }

    player.set(
      "score",
      Math.min(player.get("score") + 1, comparisonArray.length - 1)
    );

    // Re-enable the buttons after a short delay
    const selectionTimeout = setTimeout(() => {
      setSelectionButtonsEnabled(true);
    }, 1500);
  }
  const TooltipDemo = () => {
    return (
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild style={{ backgroundColor: "transparent" }}>
            <button className="IconButton">
              <QuestionMarkCircledIcon />
            </button>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className="TooltipContent" sideOffset={5} style={{ maxWidth: "50rem" }} side="bottom">
              <img src="intro/rel_eff.png"></img>
              <Tooltip.Arrow className="TooltipArrow" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    );
  };

  let guess = player.round.get("guess");
  guess = guess || guess === 0 ? guess : undefined;

  if(!selectionButtonsEnabled){
    return(
      <Box className="DialogContent">
        <Text>Please review the new options before making your next selection...</Text>
      </Box>
    )
  }
  return (
    <Flex align="center" style={{ height: '100vh' }}>
      <Box minWidth="30%">
        <CompTable configA={config_A} configB={config_B} />
      </Box>
      <Flex direction="column" justify="center" mx="5rem" gap="6rem">
        <Box>
          <Profile />
        </Box>
        <Box>
          <Flex direction="column">
            {useSlider ?
              <Heading size="5" align="center">
                Move the slider towards the PGG configuration (Option A or B) you think will have the highest <span style={{ color: "blue" }}>relative efficiency</span> {TooltipDemo()}.
              </Heading>
              :
              <Heading size="5" wrap="balance">
                Select the PGG condition in which you think a group will have the
                highest relative efficiency.
              </Heading>
            }

            <Text size="3" color="gray">
              {/* {useSlider ? 
               <Text as="p">Selecting 50% means you believe that groups in both options achieve the same relative efficiency, or that you have no prior. Moving the slider all the way to Option A or B means that you are certain that groups have higher relative efficiency in a PGG of that configuration, while intermediate slider values mean that you have some preference for Option A or B, but are uncertain.</Text>:
               <></>} */}
              <Text as="p" py="2rem" align="center">
                To the left, you can view the parameters of each condition, and 
                screenshots of their respective interfaces are below. Hover over a parameter name to see an explanation, or review the Google Doc of information <u><strong><a target="_blank" href="https://docs.google.com/document/d/1da-SZhpJkgGu62ym8Sh6R_b0opTr4EtIoG2nFkIxwEA/edit#heading=h.qs44xq8ush8n">here</a></strong></u> (link opens in a new tab).
              </Text>
            </Text>
            <Flex align="start" justify="between" mt="1rem" gap="30rem">
              <Flex direction="column" align="center">
              <Text align="center" className="text-2xl font-bold">Option A Screenshots</Text>
                <p>(click to enlarge)</p>
                <CompCarousel config={config_A} />
              </Flex>
              <Flex direction="column" align="center">
                <Text align="center" className="text-2xl font-bold">Option B Screenshots</Text>
                <p>(click to enlarge)</p>
                <CompCarousel config={config_B} />
              </Flex>
            </Flex>
          </Flex>
        </Box>



        
          <Flex justify="center">
            <Box width="100%">
              {useSlider ? (
                <Flex direction="column" gap="6">
                  <Text size="7" color="gray">
                    <Flex justify="between" align="center" gap="6">
                      <Flex direction="column" align="center">
                        <Text size="6" weight="bold">Option A</Text>
                        {guess === undefined
                          ? "-"
                          : Math.round((1 - guess) * 100)}
                        %
                      </Flex>

                      <Flex direction="column" align="center">
                        <Text size="6" weight="bold">Option B</Text>
                        {guess === undefined
                          ? "-"
                          : Math.round((guess) * 100)}
                        %
                      </Flex>
                    </Flex>
                  </Text>

                  {/* NOTE: Using the color "sky" removes color from the range. This is an override in index.css  */}
                  <Slider
                    color="sky"
                    onValueChange={(value) => {
                      player.round.set("guess", value[0]);
                    }}
                    value={[guess === undefined ? 0.5 : guess]}
                    size="3"
                    max={1}
                    min={0}
                    step={0.01}
                  />

                  <Flex justify="center">
                    <Button
                      size="4"
                      disabled={guess === undefined}
                      onClick={() => handleSubmit(player.round.get("guess"))}
                    >
                      Submit Prediction
                    </Button>
                  </Flex>
                </Flex>
              ) : (
                <Flex justify="between" align="center" gap="6">
                  <Button color="green" onClick={() => handleSubmit("A")}>
                    Select Option A
                  </Button>
                  <Button color="gray" onClick={() => handleSubmit("DRAW")}>
                    I predict no difference
                  </Button>
                  <Button color="blue" onClick={() => handleSubmit("B")}>
                    Select Option B
                  </Button>
                </Flex>
              )}
            </Box>
          </Flex>
        
          

      </Flex>


    </Flex>
  );
}