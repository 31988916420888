import { useGame } from "@empirica/core/player/classic/react";

import React from "react";
import { Profile } from "./Profile";
import { Stage } from "./Stage";

export function Game() {
  const game = useGame();

  return (
    <>
      {/* <Profile /> */}
      <Stage />
    </>
  );
}
