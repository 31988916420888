import {
  useGame,
  usePlayer,
  useRound,
  useStage,
} from "@empirica/core/player/classic/react";
import { Box, Container, Flex, Text, Button, Heading } from "@radix-ui/themes";
import React, { useState } from "react";
import { Timer } from "./components/Timer";
import * as Dialog from '@radix-ui/react-dialog';
import { TaskInstructions } from "./intro-exit/TaskInstructions.jsx";


export function Profile() {
  const player = usePlayer();
  const round = useRound();
  const stage = useStage();
  const game = useGame();
  const { surveyTask } = game.get("treatment");
  const score = player.get("score") || 0;
  const nPairsTotal = game.get("nPairsTotal");
  const nConditionsTotal = game.get("nConditionsTotal");
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isInstructionModalOpen, setIsInstructionModalOpen] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const participantSource = urlParams.get("source");

  return (
    <Container>
      <Text size="4" weight="medium" color="gray">
        <Flex justify="between">

          <Dialog.Root open={isInstructionModalOpen} onOpenChange={setIsInstructionModalOpen}>
            <Dialog.Trigger asChild>
              <Button color="blue">Instructions</Button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="DialogOverlay" />
              <Dialog.Content className="DialogContent" style={{ maxWidth: "50%" }}>
                <Dialog.Title className="DialogTitle" style={{ fontSize: "25px" }}>Predict the effect that enabling peer punishment will have on group efficiency!</Dialog.Title>
                <Text>
                  <br />
                  <Box style={{
                    background: 'var(--green-a2)',
                    border: '1px dashed var(--gray-a7)',
                  }}
                    p="4"
                  >
                    <Text>
                      <strong>In this survey, you will be shown a sequence of PGG configurations. For each configuration, you will be told the average efficiency of groups playing that PGG with punishment <u>disabled</u>, and your task is to predict the average efficiency of groups playing the same PGG but with punishment <u>enabled.</u></strong>
                    </Text>
                  </Box>
                  <br /><br />
                  <ol className="list-disc ml-2rem">
                  <li><strong>There are a total of 20 configurations to evaluate{participantSource == "prolific" ? ", and you will be paid a bonus per configuration based on how low your error was." : "."}</strong></li>
                    <br />
                    <li>The quality of your predictions will be judged by their <strong>"Squared Error": (Predicted value - True value)<sup>2</sup></strong>
                      <ol className="list-disc ml-2rem">
                        <li>The lower your error, the better your prediction. A perfect prediction has an error of 0. Because the error is squared, larger errors are penalized more harshly than smaller errors.</li>
                        {participantSource == "prolific" ? <strong><li>For each prediction, you can earn an accuracy bonus of up to $0.50 (for a perfect prediction), with the accuracy bonus decreasing as the error of the prediction increases.</li></strong> : <></>}
                      </ol>
                    </li>
                    <br />
                    <li><strong>When you're ready to evaluate the next PGG configuration, click "Submit prediction" to move on.</strong></li>
                    <br />
                    <li>At any point, you can review the definition of efficiency by hovering over the help tooltip, and review the definition of any of the PGG design parameters by hovering over its name. You may also access all the information provided in the instructions via the Google Doc.</li>
                    <br />
                    <li><strong>For the task to be considered complete, you must make at least 10 predictions.</strong> Afterwards, whenever you'd like to stop and exit the survey, please click the "Exit survey" button to proceed to the demographic survey before exiting.</li>
                    <br />
                  </ol>
                </Text>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>

          {/* <Button color="blue" onClick={() => {player.stage.set("submit", true)}}>Instructions</Button> */}
          {/* <Flex direction="column">
          <Text align="center" weight="bold">Timer</Text>
          <Timer />
          </Flex> */}
          <Flex direction="column" align="center">
            <Text><strong>Conditions compared: </strong>
              {stage.get("index")} /{" "}
              {surveyTask == "pairwise" ? nPairsTotal : nConditionsTotal}</Text>
          </Flex>


          <Dialog.Root open={isExitModalOpen} onOpenChange={setIsExitModalOpen}>
            <Dialog.Trigger asChild>
              <Button color="red">Exit survey</Button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="DialogOverlay" />
              <Dialog.Content className="DialogContent">
                <Dialog.Title className="DialogTitle">Are you sure you want to exit the survey?</Dialog.Title>
                <Dialog.Description className="DialogDescription">
                  There are pairs of conditions you haven't compared yet!
                  <br /><br />
                  If you're sure you want to leave, please take a moment to fill out the demographic information that will appear once you exit.
                </Dialog.Description>
                <Flex justify="end" gap="2rem">
                  <Dialog.Close>
                    <Button variant="outline">Cancel</Button>
                  </Dialog.Close>
                  <Dialog.Close>
                    <Button
                      color="red"
                      asChild={false}
                      onClick={() => { 
                        if(stage.get("index") > 9){
                          game.set("ended", true);
                        }
                        else{
                          alert("Please make at least 10 predictions before exiting the survey!")
                        }
                         }}
                    >
                      Exit
                    </Button>
                  </Dialog.Close>
                </Flex>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </Flex>
      </Text>
    </Container>
  );
}
