import { Box, Flex, Heading, Slider, Text } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import { Button } from "../components/Button";

// import "../node_modules/@empirica/core/dist/player-classic-react.css";

export function PGGIntroduction({ previous,next }) {
  return (
    <Flex direction="column" px="25rem" gap="6" py="7rem">


      <Heading size="8" wrap="balance" align="left">
      What are public goods games (PGGs)? 
      </Heading>
      <Text>
        <strong>A single round of a PGG has three stages:</strong>
        <br/><br/>
      <ol className="list-decimal ml-2rem">
        <li><strong>Contribution:</strong> At the beginning of each round, each player in the game receives a private endowment of coins and can decide how much of that endowment to contribute to a public fund while keeping any remaining funds from the endowment. For each round, the total contributions made to the public fund are multiplied (e.g. by 2 in the illustration below), then divided equally among players.</li>
        <br/>
        <li><strong>Outcome:</strong> In this stage, each player’s contribution is made public, and players may punish/reward each other (if the feature is enabled for their session). To punish/reward a peer, players can pay an amount of money to deduct/award coins from/to another player. </li>
        <br/>
        <li><strong>Summary:</strong> In the last stage of a PGG round, players can see a summary of their own experience of the round (coins contributed/withheld, coins gained from the public fund, coins lost/gained through reward and punishment actions), and their total payoff for the round. Each player’s payoff for a round is the sum of their share of the public fund and the remainder of their endowment, minus any costs paid to punish/reward others, and accounting for any punishments/rewards received from other players. </li>
        <br/>
      </ol>
      </Text>
      <Flex justify="center">
      <img src="intro/pgg_overview.png" className="max-w-50rem justify-center"></img>
      </Flex>



      <Heading size="8" mt="3rem" wrap="balance" align="left">
      What are the different parameters that govern PGG design? 
      </Heading>
      <Text>
      We have defined a set of parameters that, collectively, describe any public goods game that could be played on our platform. These are shown below, and more detailed descriptions will be given later:
      </Text>
      <Flex justify="center">
      <img src="intro/pgg_params.png" className="max-w-50rem justify-center"></img>
      </Flex>


      {/* <Heading size="8" mt="3rem" wrap="balance" align="left">
      Experimental design to explore the space of PGGs:
      </Heading>
      <Text>
      To explore the high-dimensional space of PGG design, we take a space-filling sample of PGG game designs from the space and run a series of “micro-experiments”. In each micro-experiment, we run only two games–one with peer punishment enabled, and one without–while holding all other parameters of the game design constant. Games are played by online participants recruited from crowdwork platforms. We then compare the collaborative behavior in the game with punishment to the collaborative behavior in the game without punishment. 
      </Text> */}
      


      <Heading size="8" mt="3rem" wrap="balance" align="left">
      What is the outcome of interest?
      </Heading>
      <Text>
      <strong>We measure a group’s collaborative behavior as a ratio between their behavior and that of a fully-cooperative group (i.e. a group in which all members contribute their full endowment in every round), which we call “efficiency”.</strong> In other words, for a given PGG design, a group’s efficiency describes how closely their cooperative behavior in the game approximated that of a hypothetical fully-cooperative group. 
      </Text>
      <Text>
      An efficiency value of 0 means that a group earned no coins, while a value of 1 means that a group earned the same amount of coins as a hypothetical group that always cooperated. For PGGs where punishment/reward are enabled, a group’s efficiency may exceed 1 or be lower than 0 due to the net impact of punishment or reward, as punishment/reward are not accounted for in the earnings of the hypothetical “always cooperating” group. 
      </Text>
      <img src="intro/eff.png" className="max-w-30rem justify-center"></img>

      <Text>
      <strong>As an illustrative example, consider a PGG with the following configuration (ignore all other parameters momentarily):</strong>
      <br/><br/>
      <ul className="list-disc ml-2rem">
        <li>5 players</li>
        <li>10 rounds</li>
        <li>Endowment is 20 coins per round</li>
        <li>Both punishment and reward are enabled </li>
      </ul>
      </Text>
      <Text>
      The group's gross earnings (i.e. the sum of coins earned by all players after all rounds are played, without accounting for punishment and reward) are 2,300 coins.  Collectively, the group spent 200 coins to deduct 600 coins (i.e. punish) each other, leading to a net deduction of 800 coins from the group's gross total. Similarly, the group spent 100 coins to reward each other with a total of 400 coins, leading to a net addition of 300 coins to the group's gross total. 
      </Text>
      <Text>
      <strong>We proceed to calculate the group's efficiency as follows:</strong>
      </Text>
      <img src="intro/example_calc_eff.png" className="max-w-50rem justify-center"></img>



      <Heading size="8" mt="3rem" wrap="balance" align="left">
      How are experiment participants recruited and incentivized?
      </Heading>
      <Text>
      Participants are recruited from Prolific, and the total pay that they receive is broken down into four components: 
      <br/><br/>
      <ul className="list-disc ml-2rem">
        <li><strong>Base pay</strong> of $1</li>
        <li><strong>Lobby pay:</strong> compensation at a rate of $15/hr for any time spent in the lobby waiting for other players to join</li>
        <li><strong>Bonus pay per round:</strong> $0.10 for each round of the game a player participates in regardless of performance, to account for variability in the duration of the task</li>
        <li><strong>Performance pay</strong> at a rate of $1 / 300 coins earned in the game
</li>
      </ul>
      </Text>
      
      <Flex justify="between">
      <Button onClick={previous} scrollToTop justify="start">
        <p>Previous</p>
      </Button>
      <Button onClick={next} scrollToTop justify="end">
        <p>Next</p>
      </Button>
      </Flex>
    </Flex>
  );
}
