import React from "react";
import { usePlayer } from "@empirica/core/player/classic/react";



// TODO: do we want to bonus nogames?  
export function FinishedExitCode() {
    const player = usePlayer();
    const source = player.get("urlParams").source;

    return (
        <div className="h-full overflow-auto">
            <div className="h-screen flex items-center justify-center">
                <div className="w-92 flex flex-col items-center gap-y-10">
                    <h2 className="text-black-700 font-medium text-xl">Thank you for participating!</h2>
                    {(source === "sspp" || source === "prolific") &&
                    <>
                    <p className="mt-2 text-black text-justify font-bold">Please submit the following code:</p>
                    <p className="text-black text-justify font-bold text-4xl">PGG24ES</p>
                    </>
                    }
                </div>
            </div>
        </div>
    );
}




