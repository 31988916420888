import { EmpiricaClassic } from "@empirica/core/player/classic";
import { EmpiricaContext } from "@empirica/core/player/classic/react";
import { EmpiricaMenu, EmpiricaParticipant } from "@empirica/core/player/react";
import { Theme } from "@radix-ui/themes";
import React from "react";
import { Game } from "./Game";
import { ExitSurveyProlific } from "./intro-exit/ExitSurvey_Prolific";
import { ExitSurveyDirect } from "./intro-exit/ExitSurvey_Direct";
import { ExitSurveySSPP } from "./intro-exit/ExitSurvey_SSPP";
import { Introduction } from "./intro-exit/Introduction";
import { PGGIntroduction } from "./intro-exit/PGGIntroduction";
import { PGGParams } from "./intro-exit/PGGParams";
import { Consent } from "./intro-exit/Consent";
import { BinaryTaskInstructions } from "./intro-exit/BinaryTaskInstructions";
import { QuantTaskInstructions } from "./intro-exit/QuantTaskInstructions";
import { isDesktop } from "react-device-detect";
import { PlayerCreate } from "./intro-exit/PlayerCreate"
import { ComprehensionCheck } from "./intro-exit/ComprehensionCheck"; 
import { QuantComprehensionCheck } from "./intro-exit/QuantComprehensionCheck"; 
import { FinishedExitCode } from "./intro-exit/FinishedExitCode";


export default function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const playerKey = urlParams.get("participantKey") || "";

  const { protocol, host } = window.location;
  const url = `${protocol}//${host}/query`;

  function introSteps({ game, player }) {
    return [Introduction, PGGIntroduction, PGGParams, QuantTaskInstructions, QuantComprehensionCheck];
  }

  function exitSteps({ game, player }) {
    if (player.get("urlParams").source == "sspp") {
      return [ExitSurveySSPP]
    }
    else if (player.get("urlParams").source == "prolific") {
      return [ExitSurveyProlific];
    }
    else {
      return [ExitSurveyDirect];
    }
  }

  if (!isDesktop) {
    return (
      <div className="mt-3 sm:mt-5 p-20 w-full max-w-screen-md mx-auto">
        <div class="prose prose-bluegray">
          <h3>Sorry, this study is only available on desktop devices.
          Please return to this page on a desktop device to participate. Thank you!
          </h3>
        </div>
      </div>
    );
  }
  
  return (
    <Theme accentColor="cyan">
      {/* <Theme accentColor="cyan" appearance="dark"> */}
      <EmpiricaParticipant url={url} ns={playerKey} modeFunc={EmpiricaClassic}>
        <div className="h-screen relative">
          <EmpiricaMenu position="bottom-left" />
          <div className="h-full overflow-auto">
            <EmpiricaContext 
            introSteps={introSteps} 
            exitSteps={exitSteps}
            playerCreate={PlayerCreate}
            finished={FinishedExitCode}
            consent={Consent}>
              <Game />
            </EmpiricaContext>
          </div>
        </div>
      </EmpiricaParticipant>
    </Theme>
  );
}
