import { Box, Flex, Heading, Slider, Text } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import { Button } from "../components/Button";

// import "../node_modules/@empirica/core/dist/player-classic-react.css";

export function PGGParams({ previous, next }) {
  return (
    <Flex direction="column" px="25rem" gap="6" py="7rem">
      <Heading size="8" wrap="balance" align="left">
      PGG Parameter Overview
      </Heading>
      <Text>
      Although PGGs are described by several parameters, not all of them are actively manipulated in our experiment. The following parameters are held constant across all games in our study: 
        <br/><br/>
      <ol className="list-disc ml-2rem">
        <li>The duration of each stage of a round (contribution, outcome, summary) is held constant at 45 seconds</li>
        <br/>
        <li>The endowment (coins given to each player at the beginning of each round) is held constant at 20 coins</li>
        <br/>
        <li>The USD monetary incentive structure (base pay, conversion rate from coins to USD, etc.) is held constant</li>
        <br/>
      </ol>
      The remaining parameters are described in the table below:
      </Text>
      <Flex justify="center">
      <img src="intro/pgg_param_details.png" className="max-w-60rem"></img>
      </Flex>
      <Flex justify="between">
      <Button onClick={previous} scrollToTop justify="start">
        <p>Previous</p>
      </Button>
      <Button onClick={next} scrollToTop justify="end">
        <p>Next</p>
      </Button>
      </Flex>
    </Flex>
  );
}
