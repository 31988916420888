import { Box, Flex, Heading, Slider, Text } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import { Button } from "../components/Button";
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";


// import "../node_modules/@empirica/core/dist/player-classic-react.css";

export function QuantTaskInstructions({ previous, next }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const urlParams = new URLSearchParams(window.location.search);
  const participantSource = urlParams.get("source");


  return (
    <Flex direction="column" px="25rem" gap="6" py="7rem">
      <Heading size="8" align="left">
        Predict the effect that enabling peer punishment will have on group efficiency!
      </Heading>
      <Box>
        <Box style={{
          background: 'var(--green-a2)',
          border: '1px dashed var(--gray-a7)',          
        }}
        p="4"
        >
          <Text>
            <strong>In this survey, you will be shown a sequence of PGG configurations. For each configuration, you will be told the average efficiency of groups playing that PGG with punishment <u>disabled</u>, and your task is to predict the average efficiency of groups playing the same PGG but with punishment <u>enabled.</u></strong>
          </Text>
        </Box>
        <Text>
          <br /><br />
          <ol className="list-disc ml-2rem">
            <li><strong>There are a total of 20 configurations to evaluate{participantSource == "prolific" ? ", and you will be paid a bonus per configuration based on how low your error was." : "."}</strong></li>
            <br />
            <li>The quality of your predictions will be judged by their <strong>"Squared Error": (Predicted value - True value)<sup>2</sup></strong>
              <ol className="list-disc ml-2rem">
                <li>The lower your error, the better your prediction. A perfect prediction has an error of 0. Because the error is squared, larger errors are penalized more harshly than smaller errors.</li>
                {participantSource == "prolific" ? <strong><li>For each prediction, you can earn an accuracy bonus of up to $0.50 (for a perfect prediction), with the accuracy bonus decreasing as the error of the prediction increases.</li></strong> : <></>}
              </ol>
            </li>
            <br />
            <li><strong>When you're ready to evaluate the next PGG configuration, click "Submit prediction" to move on.</strong></li>
            <br />
            <li>At any point on the next page, you can review the definition of efficiency by hovering over the help tooltip, and review the definition of any of the PGG design parameters by hovering over its name. You may also access all the information provided in the instructions via a Google Doc, linked to in the next page.</li>
            <br />
            <li><strong>For the task to be considered complete, you must make at least 10 predictions.</strong> Afterwards, whenever you'd like to stop and exit the survey, please click the "Exit survey" button to proceed to the demographic survey before exiting.</li>
            <br />
          </ol>
        </Text>
      </Box>

      <Flex justify="between">
        <Button onClick={previous} scrollToTop justify="start">
          <p>Previous</p>
        </Button>
        <Button onClick={next} scrollToTop justify="end">
          <p>Next</p>
        </Button>
      </Flex>
    </Flex>
  );
}
