import {
  usePlayer,
  usePlayers,
  useRound,
  useGame,
} from "@empirica/core/player/classic/react";
import { Loading } from "@empirica/core/player/react";
import React from "react";
import { Pairwise } from "./Pairwise";
import { Binary } from "./Binary";
import { Quantitative } from "./Quantitative";  

export function Stage() {
  const player = usePlayer();
  const players = usePlayers();
  const round = useRound();
  const game = useGame();
  const { surveyTask } = game.get("treatment");

  if (player.stage.get("submit")) {
    if (players.length === 1) {
      return <Loading />;
    }

    return (
      <div className="text-center text-gray-400 pointer-events-none">
        Please wait for other player(s).
      </div>
    );
  }

  // return <Pairwise useSlider/>;
  switch (surveyTask) {
    case "pairwise":
      return <Pairwise useSlider />;
    case "binary":
      return <Binary />;
    case "quantitative":
      return <Quantitative />;
    default:
      return <div>Unknown task</div>;
  }

  // return(
  //   <Pairwise />
  // );
}
