import {
  // Slider,
  useGame,
  usePlayer,
} from "@empirica/core/player/classic/react";
import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  Heading,
  Section,
  Slider,
  Text,
} from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import "../node_modules/@empirica/core/dist/player-classic-react.css";
import { BinaryTable } from "./BinaryTable";
import { CompTable } from "./Table";
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import { Profile } from "./Profile";
import { CompCarousel } from "./Carousel";
import * as Tooltip from '@radix-ui/react-tooltip';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';



export function Binary() {
  const player = usePlayer();
  const game = useGame();
  const playerScore = player.get("score");
  const pggConfiguration = game.get("pggConditions");
  const binaryArray = game.get("binaryArray");
  const binary_config = pggConfiguration[binaryArray[playerScore]];

  const [selectionButtonsEnabled, setSelectionButtonsEnabled] = useState(true);
  const [submissionButtonDisabled, setSubmissionButtonDisabled] = useState(true);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [minValue2, setMinValue2] = useState(0);
  const [maxValue2, setMaxValue2] = useState(0);

  let selectionTimeout = null;

  function handleSubmit(selection) {
    // Disable the buttons first to prevent accidental spam
    setSelectionButtonsEnabled(false);

    // Record the guess and update the player's score
    player.set("binaryScores", [
      ...player.get("binaryScores"),
      {
        config: binary_config,
        conf_neg: minValue,
        conf_null: maxValue - minValue,
        conf_pos: 100 - maxValue
      }
    ]);

    console.log(player.get("binaryScores"));

    // If all comparisons have been made, set the submit flag to true
    if (player.get("binaryScores").length === binaryArray.length) {
      console.log("All predictions have been made!");
      player.stage.set("submit", true);
    }

    player.set(
      "score",
      Math.min(player.get("score") + 1, binaryArray.length - 1)
    );
    player.round.set("guess", null);
    setSubmissionButtonDisabled(true);

    // Re-enable the buttons after a short delay
    selectionTimeout = setTimeout(() => {
      setSelectionButtonsEnabled(true);
      selectionTimeout = null;
    }, 1500);
  }

  const TooltipDemo = () => {
    return (
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild style={{ backgroundColor: "transparent" }}>
            <button className="IconButton">
              <QuestionMarkCircledIcon />
            </button>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className="TooltipContent" sideOffset={5} style={{ maxWidth: "50rem" }} side="bottom">
              <img src="intro/rel_eff.png"></img>
              <Tooltip.Arrow className="TooltipArrow" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    );
  };

  useEffect(() => {
    return () => clearTimeout(selectionTimeout);
  }, []);

  useEffect(() => {
    setSubmissionButtonDisabled(!Boolean(player.round.get("guess")));
  }, []);

  if (!selectionButtonsEnabled) {
    return (
      <Box className="DialogContent">
        <Text>Please review the new options before making your next selection...</Text>
      </Box>
    )
  }

  return (
    <Flex justify="between" align="center" style={{ height: '100vh' }}>
      <Box minWidth="40%">
        <CompTable configA={binary_config} configB={binary_config} />
      </Box>

      <Flex direction="column" justify="center" mr="10rem">
        <Box >
          <Profile />
        </Box>

        <Flex direction="column" gap="1rem" mt="3rem">
          <Heading size="5" align="left">
            Move the slider markers to indicate your confidence in whether allowing players to punish each other during this PGG will have a <span style={{ color: "red" }}>negative effect (decrease)</span>, <span style={{ color: "grey" }}>no effect</span>, or a <span style={{ color: "green" }}>positive effect (increase)</span> on <span style={{ color: "blue" }}>relative efficiency {TooltipDemo()}</span> .
          </Heading>


          <Flex justify="between" align="center" mt="2rem">
            <Flex minWidth="60%" mr="1rem">
            <Text size="3" color="gray">
              {/* {useSlider ? 
               <Text as="p">Selecting 50% means you believe that groups in both options achieve the same relative efficiency, or that you have no prior. Moving the slider all the way to Option A or B means that you are certain that groups have higher relative efficiency in a PGG of that configuration, while intermediate slider values mean that you have some preference for Option A or B, but are uncertain.</Text>:
               <></>} */}
               <Text as="p" align="left">
                To the left, you can view the parameters of the PGG configuration in question. Hover over a parameter name in the table to see an explanation, or review the Google Doc of information <u><strong><a target="_blank" href="https://docs.google.com/document/d/1da-SZhpJkgGu62ym8Sh6R_b0opTr4EtIoG2nFkIxwEA/edit#heading=h.qs44xq8ush8n">here</a></strong></u> (link opens in a new tab)
              </Text>
              {/* <Text as="p" align="left">
                To the left, you can view the parameters of the PGG configuration in question, and
                screenshots of the interface for this specific configuration are shown to the right (click to enlarge).
              </Text>
              <Text as="p" mt="2rem" align="left">
                Hover over a parameter name in the table to see an explanation, or review the Google Doc of information <u><strong><a target="_blank" href="https://docs.google.com/document/d/1da-SZhpJkgGu62ym8Sh6R_b0opTr4EtIoG2nFkIxwEA/edit#heading=h.qs44xq8ush8n">here</a></strong></u> (link opens in a new tab).
              </Text> */}
            </Text>
            </Flex>
            {/* <Flex>
              <CompCarousel config={binary_config} />
            </Flex> */}
          </Flex>

          <Flex justify="between" mt="3rem">
            <Flex direction="column" align="center">
              <Text size="4" color="red">Negative Effect</Text>
              <Text size="9" color="red">
                {submissionButtonDisabled
                  ? "--%" : `${minValue}%`}
              </Text>
            </Flex>
            <Flex direction="column" align="center">
              <Text size="4" color="grey">No Effect</Text>
              <Text size="9" color="grey">
                {submissionButtonDisabled
                  ? "--%" : `${maxValue - minValue}%`}
              </Text>
            </Flex>
            <Flex direction="column" align="center">
              <Text size="4" color="lime">Positive Effect</Text>
              <Text size="9" color="lime">
                {submissionButtonDisabled
                  ? "--%" : `${100 - maxValue}%`}
              </Text>
            </Flex>
          </Flex>

          <Box>
            <MultiRangeSlider
              min={0}
              max={100}
              minValue={33}
              maxValue={66}
              canMinMaxValueSame={true}
              onInput={(e) => {
                setMinValue(e.minValue);
                setMaxValue(e.maxValue);
              }}
              onChange={(e) => {
                setMinValue2(e.minValue);
                setMaxValue2(e.maxValue);
                setSubmissionButtonDisabled(false);
              }}
              label={false}
              ruler={false}
              preventWheel={true}
              style={{ border: "none", boxShadow: "none", padding: "15px 10px" }}
              barLeftColor="red"
              barInnerColor="#d3d3d3"
              barRightColor="lime"
              thumbLeftColor="grey"
              thumbRightColor="grey"
            />
          </Box>

          <Flex justify="center" mt="2rem">
            <Button
              size="4"
              disabled={submissionButtonDisabled}
              onClick={() => handleSubmit("A")}
            >
              Submit Prediction
            </Button>
          </Flex>

        </Flex>
      </Flex>
    </Flex>
  )
}
