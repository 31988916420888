import { Box, Flex, Heading, Slider, Text } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import { Button } from "../components/Button";
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";


// import "../node_modules/@empirica/core/dist/player-classic-react.css";

export function QuantComprehensionCheck({ previous, next }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [coinQuestion, setCoinQuestion] = useState(undefined);
  const [punishQuestion, setPunishQuestion] = useState(undefined);
  const [efficiencyQuestion, setEfficiencyQuestion] = useState(undefined);
  const [punishEffQuestion, setPunishEffQuestion] = useState(undefined);

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const q1_correct = coinQuestion?.replaceAll(' ', '') === "10";
  const q2_correct = punishQuestion?.replaceAll(' ', '') === "6";
  const q3_correct = parseFloat(efficiencyQuestion) === 75;

  // const q4_correct = minValue2 === 50 && maxValue2 === 50;

  // console.log(q1_correct, q2_correct, q3_correct, q4_correct);

  return (
    <Flex direction="column" px="25rem" gap="6" py="7rem">
      <Heading size="8" wrap="balance" align="left">
        Quiz
      </Heading>
      <Text><strong>To proceed to the survey, please complete the following quiz:</strong></Text>

      <Box>
        {attemptedSubmit && !q1_correct ?
          <Text color="red" weight="bold">The answer to this question is incorrect.<br /></Text> : null}
        <Text size="7" weight="bold">Question 1:</Text>
        <Text>
          <p>
            Imagine that in a given round, the marginal per capita return (MPCR, defined as the multiplier for the public fund divided by the number of players) is <sup>2</sup>&frasl;<sub>3</sub>, and players contributed the following amounts:
          </p>

          <ul className="list-disc ml-2rem">
            <li>Player 1: 10 Coins</li>
            <li>Player 2: 4 Coins</li>
            <li>Player 3: 1 Coins</li>
          </ul>
          <br />

          How much does each player receive from the public fund in this round? <br />
          (public fund distribution = sum of contributions x MPCR)
        </Text>
      </Box>
      <input
        type="number"
        id="coinQuestion"
        placeholder="Please answer using only numbers."
        value={coinQuestion}
        onChange={(e) => setCoinQuestion(e.target.value)}
      />

      <Box>
        {attemptedSubmit && !q2_correct ?
          <Text color="red" weight="bold">The answer to this question is incorrect.<br /></Text> : null}
        <Text size="7" weight="bold">Question 2:</Text>
        <Text>
          <p>Imagine a PGG with a punishment cost of 2 coins, where each punishment deducts 3 coins from the punished player.</p>
          <br />
          <p>If Player A pays 4 coins to punish Player B, how many coins does Player B lose?</p>
        </Text>
      </Box>
      <input
        type="number"
        id="punishQuestion"
        placeholder="Please answer using only numbers."
        value={punishQuestion}
        onChange={(e) => setPunishQuestion(e.target.value)}
      />

      <Box>
        {attemptedSubmit && !q3_correct ?
          <Text color="red" weight="bold">The answer to this question is incorrect.<br /></Text> : null}
        <Text size="7" weight="bold">Question 3:</Text>
        <Text>
          <p>A group playing a PGG collects a total of 4500 coins. A hypothetical "always cooperating" group playing the same PGG would earn 6000 coins.</p>
          <br />
          <p>What is the group's efficiency, as a percentage?</p>
        </Text>
      </Box>
      <div className="flex gap-x-4">
        <input
          type="number"
          id="efficiencyQuestion"
          placeholder="Enter a number between 0 and 100."
          value={efficiencyQuestion}
          onChange={(e) => setEfficiencyQuestion(e.target.value)}
          className="w-300px"
        />
        <p className="text-5xl font-bold">%</p>
      </div>


      {/* <Box>
        {attemptedSubmit && !q4_correct ?
          <Text color="red" weight="bold">The answer to this question is incorrect.<br /></Text> : null}
        <Text size="7" weight="bold">Question 4:</Text>
        <Text>
          <p>A group is playing a PGG with punishment enabled. They earned a total of 3000 coins from the endowments and distributions of the public fund, but spent a total of 300 coins to deduct a total of 700 coins from each other as punishments.
            A hypothetical "always cooperating" group playing the same PGG would earn 6000 coins.</p>
          <br />
          <p>What is the group's efficiency?</p>
        </Text>
      </Box>
      <input
        type="number"
        id="punishEffQuestion"
        placeholder="Enter a number between 0 and 1."
        value={punishEffQuestion}
        onChange={(e) => setPunishEffQuestion(e.target.value)}
      /> */}

      <Flex justify="between">
        <Button onClick={previous} scrollToTop justify="start">
          <p>Previous</p>
        </Button>
        <Button onClick={() => {
          setAttemptedSubmit(true);
          if (q1_correct && q2_correct && q3_correct) {
            next();
          }
          else {
            alert("Please answer all questions correctly to proceed.");
          }
        }} scrollToTop justify="end">
          <p>Next</p>
        </Button>
      </Flex>
    </Flex>
  );
}
