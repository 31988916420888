import { Box, Flex, Progress, Table, Text } from "@radix-ui/themes";
import * as Tooltip from '@radix-ui/react-tooltip';
import { PlusIcon, QuestionMarkCircledIcon} from '@radix-ui/react-icons';
import { Backpack, CircleCheck, CircleX } from "lucide-react";
import React from "react";

const useColoredLabels = true;
const useStrikedLabels = false;

function True() {
  return (
    <Text color="green" weight="bold">
      Yes
      {/* <CircleCheck size={20} /> */}
    </Text>
  );
}

function False() {
  return (
    <Text color="red" weight="bold">
      No
      {/* <CircleX size={20} /> */}
    </Text>
  );
}

function BinaryRow({ label, description, isTrueA, isTrueB }) {
  let lbl = label;
  if (isTrueB === undefined && useColoredLabels) {
    lbl = <Text color={isTrueA ? "green" : "red"}>{lbl}</Text>;
  }

  if (isTrueB === undefined && useStrikedLabels && !isTrueA) {
    lbl = <Text className="decoration-line-through">{lbl}</Text>;
  }

  return (
    <Row
      label={lbl}
      valueA={isTrueA ? <True /> : <False />}
      valueB={
        isTrueB === undefined ? undefined : isTrueB ? <True /> : <False />
      }
      description={description}
    />
  );
}

function ScaleRow({ label, description, max, valueA, valueB, isTrue = true, isTrueA = true, isTrueB = true}) {
  if (!isTrue) {
    return <Row label={label} valueA="-" valueB={valueB && "-"} />;
  }

  const valA = (
    <>
      <Box width="30px" align="right">{isTrueA ? valueA : "-"}</Box>
      {/* <Progress color="green" value={(valueA / max) * 100} /> */}
    </>
  );

  const valB = valueB && (
    <>
      {/* <Progress
        className="rotate-180"
        color="green"
        value={(valueB / max) * 100}
      /> */}
      <Box width="50px" align="left">
        {isTrueB ? valueB : "-"}
      </Box>
    </>
  );

  return <Row label={label} valueA={valA} valueB={valB} description={description}/>;
}

function Row({ label, valueA, valueB, description}) {
  // const width = valueB !== undefined ? "33%" : "50%";
  // const align = valueB !== undefined ? "center" : "end";

  const width="10%";
  const align="end";

  return (
    <Table.Row>
      {false && (
        <Table.Cell width={"25%"}>
          <Flex gap="2" h="100%" align="center" justify="end">
            {valueA}
          </Flex>
        </Table.Cell>
      )}
      <Table.RowHeaderCell justify={align} width={"50%"}>
      {TooltipDemo(label, description)}
      </Table.RowHeaderCell>
      <Table.Cell width={width}>
        <Flex gap="2" h="100%" align="right" justify="start">
          {valueB}
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
}

function SepRow({ label }) {
  return (
    <Table.Row className="bg-light dark:bg-dark">
      <Table.RowHeaderCell justify="center" colSpan={3}>
        <Text color="gray" weight="bold">
          {label}
        </Text>
      </Table.RowHeaderCell>
    </Table.Row>
  );
}

const TooltipDemo = (label, description) => {
  return (
    <Tooltip.Provider delayDuration="200">
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <Text weight="bold"> {label} </Text>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="TooltipContent" sideOffset={5}>
          {description}
            <Tooltip.Arrow className="TooltipArrow" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};


export function CompTable({ configA, configB }) {
  return (
    <Box py="4">
      <Flex justify="center">
        <Table.Root size="1" variant="surface">
          {false && (
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell justify="center">Option A</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell align="center">
                  Parameter
                </Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell justify="center">
                  Option B
                </Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>
          )}

          <Table.Body>
            <SepRow label="Game Structure" />

            <ScaleRow
              label="Players"
              valueA={configA.n_players}
              valueB={configB?.n_players}
              max={20}
              description={"Number of players in the game"}
            />

            <ScaleRow
              label="Rounds"
              valueA={configA.n_rounds}
              valueB={configB?.n_rounds}
              max={20}
              description={"Number of rounds in the game"}
            />

            <BinaryRow
              label="Is chat enabled?"
              isTrueA={configA.chat}
              isTrueB={configB?.chat}
              description={<div>
                <p className="text-lg">Is chat enabled?</p>
                <br/>
                <ul className="list-disc ml-5 space-y-5">
                  <li>When true, players can chat with each other through a chat window to the side of the game interface for the entire duration of the game</li>
                  <li>When false, players cannot chat with each other</li>
                </ul>
              </div>}
            />

            <BinaryRow
              label="All or nothing contributions?"
              isTrueA={configA.all_or_nothing}
              isTrueB={configB?.all_or_nothing}
              description={<div>
                <p className="text-lg">“All or Nothing” contributions</p>
                <br/>
                <ul className="list-disc ml-5 space-y-5">
                  <li>When true, players can only choose between contributing nothing and contributing their entire endowment – intermediate contributions are not allowed</li>
                  <li>When false, players can choose how much of their endowment they would like to contribute</li>
                </ul>
              </div>}
            />

            <BinaryRow
              label="Is contribution the default?"
              isTrueA={configA.contrib_default}
              isTrueB={configB?.contrib_default}
              description={<div>
                <p className="text-lg">Is contribution the default?</p>
                <br/>
                <ul className="list-disc ml-5 space-y-5">
                  <li>When true, players decide how much of their endowment to move from the public fund back into their hand</li>
                  <li>When false, players decide how much of their endowment to move from their hand into the public fund</li>
                </ul>
              </div>}
            />

            <SepRow label="Monetary Stakes" />

            <ScaleRow
              label="MPCR"
              valueA={configA.mpcr}
              valueB={configB?.mpcr}
              max={1}
              description={<div>
                <p className="text-lg">Marginal per capita return (MPCR)</p>
                <br/>
                <p>Defined as the multiplier for the public fund divided by the number of players. For example, in a game with 5 players and the multiplier being 3, the MPCR is 3 divided by 5, which is 0.6. This means for every coin contributed by each player, its marginal return is 0.6 coins per player.</p>
              </div>}
            />

            <SepRow label="Peer Incentives" />

            {/* <BinaryRow
              label="Is punishment enabled?"
              isTrueA={configA.punishment_exists}
              isTrueB={configB?.punishment_exists}
            /> */}

            <ScaleRow
              label="Punishment cost"
              isTrueA={configA.punishment_exists}
              isTrueB={configB.punishment_exists}
              valueA={`${configA.punishment_cost} coins`}
              valueB={`${configA.punishment_cost} coins`}
              max={10}
              description={<div>
                <p className="text-lg">Punishment cost</p>
                <br/>
                <ul className="list-disc ml-5 space-y-5">
                  <li>When punishment is enabled, this is the cost in coins to impose a single unit of punishment</li>
                  <li>A player can enforce multiple punishments. For example, if the cost of one unit of punishment is 3 coins, then a player needs to pay 6 coins to enforce 2 units of punishments</li>
                </ul>
              </div>}
            />

            <ScaleRow
              label="Punishment effectiveness"
              isTrueA={configA.punishment_exists}
              isTrueB={configB.punishment_exists}
              valueA={configA.punishment_magnitude}
              valueB={configB?.punishment_magnitude}
              max={10}
              description={<div>
                <p className="text-lg">Punishment effectiveness</p>
                <br/>
                <ul className="list-disc ml-5 space-y-5">
                  <li>The coins deducted from a player for each coin spent punishing them.</li>
                  <li>For example, if a single punishment costs 2 coins and the effectiveness is 1.5, then the punished player will have 3 coins deducted from them for each punishment imposed. Coins deducted from the punished player are <strong>not</strong> given to the punisher.</li>
                </ul>
              </div>}
            />

            <BinaryRow
              label="Is reward enabled?"
              isTrueA={configA.reward_exists}
              isTrueB={configB?.reward_exists}
              description={
                <div>
                <p className="text-lg">Is reward enabled?</p>
                <br/>
                <ul className="list-disc ml-5 space-y-5">
                  <li>When true, players are allowed to reward any other player by paying an amount of money to award coins to another player</li>
                  <li>When false, players are not allowed to reward other players</li>
                </ul>
              </div>
              }
            />
            <ScaleRow
              label="Reward cost"
              isTrueA={configA.reward_exists}
              isTrueB={configB.reward_exists}
              valueA={`${configA.reward_cost} coins`}
              valueB={`${configB?.reward_cost} coins`}
              max={10}
              description={
                <div>
                <p className="text-lg">Reward cost</p>
                <br/>
                <ul className="list-disc ml-5 space-y-5">
                  <li>The cost in coins of granting a single reward</li>
                  <li>A player can grant multiple rewards. For example, if the cost of one reward is 3 coins, then a player needs to pay 6 coins to grant 2 units of reward</li>
                </ul>
              </div>
              }
            />

            <ScaleRow
              label="Reward effectiveness"
              isTrueA={configA.reward_exists}
              isTrueB={configB.reward_exists}
              valueA={configA.reward_magnitude}
              valueB={configB?.reward_magnitude}
              max={10}
              description={<div>
                <p className="text-lg">Reward effectiveness</p>
                <br/>
                <ul className="list-disc ml-5 space-y-5">
                  <li>The coins awarded to a player for each coin spent rewarding them.</li>
                  <li>For example, if a single reward costs 2 coins and the effectiveness is 1.5, then the rewarded player will have 3 coins given to them for each reward granted.</li>
                </ul>
              </div>}
            />

            <SepRow label="Information Display" />

            <BinaryRow
              label="Is number of rounds known?"
              isTrueA={configA.n_rounds_shown}
              isTrueB={configB?.n_rounds_shown}
              description={<div>
                <p className="text-lg">Is the number of rounds known to players?</p>
                <p>In other words, do the players know when the game ends?</p>
                <br/>
                <ul className="list-disc ml-5 space-y-5">
                  <li>When true, players know the total number of rounds and the number of rounds left in the game</li>
                  <li>When false, players do not know the total number of rounds, nor do they know the number of rounds left in the game</li>
                </ul>
              </div>}
            />

            <BinaryRow
              label="Are peer outcomes known?"
              isTrueA={configA.peer_info_shown}
              isTrueB={configB?.peer_info_shown}
              description={<div>
                <p className="text-lg">Are peer outcomes known? </p>
                <br/>
                <ul className="list-disc ml-5 space-y-5">
                  <li>When true, players can see how much their peers gained and lost in each round</li>
                  <li>When false, players cannot see how much their peers gained and lost in each round</li>
                </ul>
              </div>}
            />

            <BinaryRow
              label="Are punishers/rewarders known?"
              isTrueA={configA.punish_reward_id_known}
              isTrueB={configB?.punish_reward_id_known}
              description={<div>
                <p className="text-lg">Are punishers/rewarders known?</p>
                <br/>
                <ul className="list-disc ml-5 space-y-5">
                  <li>When true, players know who have punished/rewarded them</li>
                  <li>When false, players can see that they have been punished/rewarded, but not by whom</li>
                </ul>
              </div>}
            />
          </Table.Body>
        </Table.Root>
      </Flex>
    </Box>
  );
}
