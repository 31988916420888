import React from "react";
import { Button } from "../components/Button";

export function Consent({ onConsent }) {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const consent = data.get("consent");
    if (consent === "yes") {
      onConsent();
    } else {
      window.location.href = "https://www.prolific.com/";
    }
  };

  return (
    <div className="mt-3 sm:mt-5 p-20 w-full max-w-screen-md mx-auto">
      <div class="prose prose-bluegray">
        <h3>Consent form</h3>

        <p>This experiment is part of a MIT scientific project. Your decision to participate in this 
          experiment is entirely voluntary. There are no known or anticipated risks to participating 
          in this experiment. There is no way for us to identify you. The only information we will have, 
          in addition to your responses, is the timestamps of your interactions with our site. The results of 
          our research may be presented at scientific meetings or published in scientific journals. 
          Clicking on the "AGREE" button indicates that you are at least 18 years of age, and agree to participate voluntarily.
        </p>
        <p>The survey will take around 20 minutes to complete.</p>
      </div>
      <div className="mt-6">
        <form onSubmit={handleSubmit}>
          <fieldset>
            <legend className="text-gray-700 mb-4">
              Please select one of the following options. If you choose not to
              participate, the survey will end immediately.
            </legend>
            <div className="text-gray-700 space-y-2 mb-8">
              <div className="space-x-2">
                <input
                  type="radio"
                  id="consentGiven"
                  name="consent"
                  value="yes"
                  required
                />
                <label for="consentGiven">I agree to participate</label>
              </div>
              <div className="space-x-2">
                <input
                  type="radio"
                  id="consentNotGiven"
                  name="consent"
                  value="no"
                />
                <label for="consentNotGiven">
                  I don't agree to participate
                </label>
              </div>
            </div>
            <div>
              <Button type="submit" scrollToTop>
                <p>Next</p>
              </Button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
}
