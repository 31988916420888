import { Box, Flex, Heading, Slider, Text } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import { Button } from "../components/Button";

// import "../node_modules/@empirica/core/dist/player-classic-react.css";

export function Introduction({ next }) {
  return (
    <Flex direction="column" px="25rem" gap="6" py="10rem">
      <Heading size="8" wrap="balance" align="center">
        The Heterogeneous Effects of Peer Punishment Across Cooperative Settings
      </Heading>
      {/* <Heading size="3" wrap="balance" align="center">
        Mohammed Alsobay, David G. Rand, Duncan J. Watts, Abdullah Almaatouq
      </Heading> */}
      <Heading size="6" wrap="balance" align="left">
        Introduction and purpose of the survey: 
      </Heading>
      <Text>
      Peer punishment has been shown to moderate social cooperation in public goods games (PGGs). This study aims to understand how the effect of peer punishment on cooperation in public goods games (PGGs) varies with the parameters of the game design. For example, are punishments more effective in small or large groups? 
      </Text>
      <Text>
      You will be asked to make predictions about how collaborative behavior in a public goods game (PGG) is affected by whether participants in the game are able to punish each other or not. 
      </Text>
      <Text>
      On the next page, we will provide information about the experimental design; you do not have to memorize all this information. On the pages where we collect responses from you, you will be given a link to a Google Doc with the relevant information for review.
      </Text>
      <Box align="end">
      <Button onClick={next} scrollToTop>
        <p>Next</p>
      </Button>
      </Box>
    </Flex>
  );
}
