import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Modal from "react-modal";
import { Box, Flex, Heading, Slider, Text } from "@radix-ui/themes";


export function CompCarousel({ config }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = (index) => {
    setSelectedImage(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const imageUrls = [
    "contrib.png",
    "outcome.png",
    "summary.png",
    // ...
  ];

  return (
    <>
      <Carousel showThumbs={false} onClickItem={handleImageClick}>
        {imageUrls.map((url, index) => (
          <div key={index}>
            <img src={url} alt={`Image ${index}`} />
            {/* <p className="legend">{url}</p> */}
          </div>
        ))}
      </Carousel>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Enlarged Image"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            maxWidth: '90%',
            padding: '20px',
            boxSizing: 'border-box',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          },
        }}
      >
        <Flex>
        <button
          onClick={() => closeModal()}
          style={{
            position: 'absolute',
            top: '0px',
            right: '0px',
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            background: 'black',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span style={{ fontSize: '30px', color: 'white' }}>&times;</span>
        </button>
        </Flex>

          <Box mt="30px">
        <Carousel showThumbs={false} onClickItem={handleImageClick}>
          {imageUrls.map((url, index) => (
            <div key={index}>
              <img src={url} alt={`Image ${index}`} />
              <p className="legend">Contribution Phase</p>
            </div>
          ))}
        </Carousel>
        </Box>
      </Modal>
    </>
  );
}
