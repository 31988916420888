import {
  // Slider,
  useGame,
  usePlayer,
  useStage,
  useStageTimer
} from "@empirica/core/player/classic/react";
import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  Heading,
  Section,
  Slider,
  Text,
} from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import "../node_modules/@empirica/core/dist/player-classic-react.css";
import { BinaryTable } from "./BinaryTable";
import { CompTable } from "./Table";
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import { Profile } from "./Profile";
import { CompCarousel } from "./Carousel";
import * as Tooltip from '@radix-ui/react-tooltip';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';


export function Quantitative() {
  const player = usePlayer();
  const game = useGame();
  const playerScore = player.get("score");
  const pggConfiguration = game.get("pggConditions");
  const binaryArray = game.get("binaryArray");
  const stage = useStage();
  const binary_config = pggConfiguration[stage.get("config")];
  const timer = useStageTimer();

  const countdownDuration = 7.5*1000;
  const elapsed = timer?.elapsed ?? 0;
  const timeLeft = Math.max(0, countdownDuration - elapsed);
  const [quantPrediction, setQuantPrediction] = useState(null);
  
  function handleSubmit(selection) {
    player.stage.set("prediction", 
      {
        config: binary_config,
        quantPrediction: quantPrediction
      }
    );
    player.stage.set("submit", true);
  }

  const TooltipDemo = () => {
    return (
      <Tooltip.Provider delayDuration="200">
        <Tooltip.Root>
          <Tooltip.Trigger asChild style={{ backgroundColor: "transparent" }}>
            <button className="IconButton">
              <QuestionMarkCircledIcon />
            </button>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className="TooltipContent" sideOffset={5} style={{ maxWidth: "30rem" }} side="bottom">
              <img src="intro/eff.png"></img>
              <Tooltip.Arrow className="TooltipArrow" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    );
  };

  if ((!timer || timer.elapsed < 1500) & (stage.get("index") != 0)) {
    return (
      <Box className="DialogContent" align="center">
        <Text>Please review the new information before making your next prediction...</Text>
      </Box>
    )
  }

  function isNumber(value) {
    return !isNaN(parseFloat(value));
  }

  return (
    <Flex justify="between" align="center" style={{ height: '100vh' }}>
      <Box minWidth="30%">
        <CompTable configA={binary_config} configB={binary_config} />
      </Box>

      <Flex direction="column" justify="center" mr="10rem">
        <Box >
          <Profile />
        </Box>

        <Flex direction="column" gap="1rem" mt="3rem">
          {/* <Heading size="5" align="left">
            Knowing the average <span style={{ color: "blue" }}>efficiency{TooltipDemo()}</span>  (cooperation) level that a group achieves for this PGG when punishment is <u><strong>disabled</strong></u>, provide your prediction of the <span style={{ color: "blue" }}>efficiency{TooltipDemo()}</span>  level that an average group would achieve playing <strong>the same PGG with punishment <u>enabled.</u></strong>
          </Heading> */}
          {/* <Flex gap="5rem">
            <Heading size="5" align="left">
              Knowing the average <span style={{ color: "blue" }}>efficiency{TooltipDemo()}</span>  (cooperation) level that a group achieves for this PGG when punishment is <strong>disabled</strong>...
            </Heading>
            <Heading size="5" align="right">
              ...provide your prediction of the <span style={{ color: "blue" }}>efficiency{TooltipDemo()}</span>  level that an average group would achieve for <strong>the same PGG with punishment enabled.</strong>
            </Heading>
          </Flex> */}


          <Flex justify="between" align="center" mt="2rem">
            <Flex minWidth="60%" mr="1rem">
              <Text size="3" color="gray">
                {/* {useSlider ? 
               <Text as="p">Selecting 50% means you believe that groups in both options achieve the same relative efficiency, or that you have no prior. Moving the slider all the way to Option A or B means that you are certain that groups have higher relative efficiency in a PGG of that configuration, while intermediate slider values mean that you have some preference for Option A or B, but are uncertain.</Text>:
               <></>} */}
                <Text as="p" align="center">
                  To the left, you can view the parameters of the PGG configuration in question. Hover over a parameter name in the table to see an explanation, or review the Google Doc of information <u><strong><a target="_blank" href="https://docs.google.com/document/d/1da-SZhpJkgGu62ym8Sh6R_b0opTr4EtIoG2nFkIxwEA/edit#heading=h.qs44xq8ush8n">here</a></strong></u> (link opens in a new tab)
                </Text>
                {/* <Text as="p" align="left">
                To the left, you can view the parameters of the PGG configuration in question, and
                screenshots of the interface for this specific configuration are shown to the right (click to enlarge).
              </Text>
              <Text as="p" mt="2rem" align="left">
                Hover over a parameter name in the table to see an explanation, or review the Google Doc of information <u><strong><a target="_blank" href="https://docs.google.com/document/d/1da-SZhpJkgGu62ym8Sh6R_b0opTr4EtIoG2nFkIxwEA/edit#heading=h.qs44xq8ush8n">here</a></strong></u> (link opens in a new tab).
              </Text> */}
              </Text>
            </Flex>
            {/* <Flex>
              <CompCarousel config={binary_config} />
            </Flex> */}
          </Flex>

          <Flex justify="between" mt="3rem">
            <Flex direction="column" align="center" maxWidth="40%">
              <Text size="4" >For the PGG configuration given, the average <span style={{ color: "blue" }}>efficiency {TooltipDemo()}</span> level among groups playing <strong>with punishment disabled</strong> was:  </Text>
              <Text size="9" mt="1rem">
                {binary_config.control_efficiency} %
              </Text>
            </Flex>
            <Flex direction="column" align="center" maxWidth="40%">
              <Text size="4" color="grey">I predict that <strong>enabling punishment</strong> will cause the average <span style={{ color: "blue" }}>efficiency {TooltipDemo()}</span> level to change to:</Text>
              <Flex direction="row" mt="1rem" gap="10px">
                <input
                  type="number"
                  style={{ width: "10rem", fontSize: "3rem" }}
                  onChange={(e) => {
                    setQuantPrediction(e.target.value);
                  }}
                />
                <Text size="9" color="grey">
                  %
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex justify="center" mt="2rem">
            <Button
              size="4"
              disabled={!isNumber(quantPrediction) || timeLeft > 0}
              onClick={() => handleSubmit("A")}
            >
              {timeLeft > 0 ? `Submission enabled in ${Math.floor(timeLeft/1000)} seconds...` : `Submit Prediction`}
            </Button>
          </Flex>

        </Flex>
      </Flex>
    </Flex>
  )
}
